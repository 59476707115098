<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <!-- Filter , Action Buttons and Modal -->
    <section>
      <div class="filters">
        <div class="filterDropDown">
          <!-- :cols="filterCols" -->
          <header-filters
            :pre-select-filter="preSelectFilter"
            :bread-crumbs="breadCrumbs"
            :no-filters="noFilters"
            :is-filters-show="isFiltersShow"
            :filter-names="filterNames"
            :is-date-show="showDate"
            :date-type="dateType"
            :is-single-date-show="isSingleDateShow"
            :date-selected="dateSelected"
            :date-custom-options="dateCustomOptions"
            :date-validation="dateValidation"
            :search-label="searchLabel"
            :product-disable="productDisable"
            :status-options="statusOptions"
            @onChange="onFiltersChange"
          >
            <slot name="addFilters" />
          </header-filters>
        </div>
        <div class="button-modals">
          <slot name="actionModals" />
        </div>
      </div>
    </section>
    <!-- Filter , Action Buttons and Modal -->
    <div class="Desc">
      <slot name="description" />
    </div>

    <!-- Pagination -->
    <section>
      <pagination
        v-if="meta.page"
        :sub-screen="subScreen"
        :active-page="meta.page"
        :per-page="meta.itemsPerPage"
        :per-page-options="perPageOptions"
        :total-items="meta.totalItems"
        :last-page="meta.lastPage"
        @change="onPaginationChange"
      />
    </section>
    <!-- Pagination -->
    <!-- Data Table -->
    <section>
      <div class="d-flex justify-end">
        <download-file-buttons
          v-if="items.length > 0 && exportOptions.length > 0"
          :loading="isDownloading"
          :export-options="exportOptions"
          @click="downloadFile"
        />
      </div>
      <v-data-table
        v-model="selectedRows"
        :headers="headers"
        :items="items"
        :loading="isLoading"
        :items-per-page="meta.itemsPerPage"
        :show-select="multiSelectRows"
        class="elevation-0"
        hide-default-footer
      >
        <!-- :item-class="itemRowBackground" -->
        <!-- @item-selected="whenRowSelect" -->
        <!-- Data Table Scroll Horizontal -->
        <template v-slot:[`footer`]>
          <data-table-scroll
            v-if="items.length > 0 && verticalScroll"
            :data-table-key="dataTableKey"
          />
        </template>
        <!-- Data Table Scroll Horizontal -->
        <!-- When No Record  -->
        <template v-slot:no-data>
          <!-- <span
            v-if="
              (authUser.isAdmin || authUser.user_nature == 'fleet') &&
              !options.tenant_id
            "
          >
            {{ $t("please_select_a_tenant") }}
          </span>
          <span v-else>
            {{ $t("no_data_available") }}
          </span> -->
          <span>
            {{ $t("no_data_available") }}
          </span>
        </template>
        <!-- When No Record  -->

        <!-- Dynamic Column Slots -->
        <template
          v-for="col in headers"
          v-slot:[`item.${col.value}`]="{ item }"
        >
          <slot
            :name="col.value"
            :item="item"
          >
            {{ item[col.value] }}
          </slot>
        </template>
        <!-- Dynamic Column Slots -->

        <!-- actions -->
        <!-- <template v-slot:[`item.actions`]="{ item }">
          <slot
            name="action"
            :item="item"
          />
        </template> -->
        <!-- actions -->

        <!-- <template v-slot:[`item.assigned`]="{ item }">
          <slot
            name="assigned"
            :item="item"
          >
            {{ item.assigned }}
          </slot>
        </template>

        <template v-slot:[`item.master_switch`]="{ item }">
          <slot
            name="master_switch"
            :item="item"
          >
            {{ item.master_switch }}
          </slot>
        </template> -->
      </v-data-table>
    </section>

    <!-- Data Table -->

    <slot />
  </v-container>
</template>
<script>
import { debounce } from "lodash";
import { mapGetters } from "vuex";

export default {
  components: {
    HeaderFilters: () => import("./MainFilters.vue"),
    Pagination: () => import("./Pagination.vue"),
    dataTableScroll: () => import("@/components/base/dataTableScroll.vue"),
    downloadFileButtons: () =>
      import("@/components/base/DownloadFileButtons.vue"),
  },
  props: {
    subScreen: {
      type: Number,
      default() {
        return 0;
      },
    },
    isLoading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    breadCrumbs: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    isDownloading: {
      type: Boolean,
      default() {
        return false;
      },
    },
    noFilters: {
      type: Boolean,
      default() {
        return false;
      },
    },
    isFiltersShow: {
      type: Boolean,
      default() {
        return true;
      },
    },
    // filterCols: {
    //   type: String,
    //   default() {
    //     return "2";
    //   },
    // },
    meta: {
      type: Object,
      default() {
        return {};
      },
    },
    perPageOptions: {
      type: Array,
      default() {
        return [];
      },
    },
    headers: {
      type: Array,
      default() {
        return {};
      },
    },
    items: {
      type: Array,
      default() {
        return [];
      },
    },
    showDate: {
      type: Boolean,
      default() {
        return false;
      },
    },

    dateType: {
      type: String,
      default() {
        return "date";
      },
    },
    isSingleDateShow: {
      type: Boolean,
      default() {
        return false;
      },
    },
    dateSelected: {
      type: Boolean,
      default: () => {
        return true;
      },
    },
    dateValidation: {
      type: Boolean,
      default() {
        return true;
      },
    },
    dateCustomOptions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    verticalScroll: {
      type: Boolean,
      default() {
        return true;
      },
    },
    preSelectFilter: {
      type: Boolean,
      default() {
        return false;
      },
    },
    telematicRows: {
      type: Boolean,
      default() {
        return false;
      },
    },
    telematicMassageRows: {
      type: Boolean,
      default() {
        return false;
      },
    },

    filterNames: {
      type: Array,
      default() {
        return [];
      },
    },
    multiSelectRows: {
      type: Boolean,
      default() {
        return false;
      },
    },
    searchLabel: {
      type: String,
      default() {
        return "chassis_no";
      },
    },
    productDisable: {
      type: Boolean,
      default() {
        return false;
      },
    },
    statusOptions: {
      type: Array,
      default() {
        return [
          { id: "active", name: "Active" },
          { id: "inactive", name: "Inactive" },
        ];
      },
    },
    dataTableKey: {
      type: Number,
      default() {
        return 0;
      },
    },
    exportOptions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      firstDrop: false,
      pagination: {},
      selectedRows: [],
      options: {},
    };
  },
  computed: {
    ...mapGetters({
      authUser: "getUser",
    }),
  },
  watch: {
    options: {
      handler: debounce(async function () {
        // if (this.isShowFilters) {
        // }
        this.$emit("filterOption", { ...this.options, ...this.pagination });
      }, 500),
      deep: true,
    },
    selectedRows(val) {
      this.$emit("input", val);
    },
    "options.tenant_id": {
      handler: function () {
        this.selectedRows = [];
      },
    },
  },
  mounted() {
    // for Paginations History Maintain
    if (this.subScreen == 0) {
      this.options.page = this.$route?.query?.page ?? 1;
      this.options.itemsPerPage =
        this.$route?.query?.perPage ?? this.perPageOptions[0] ?? 20;
    } else if (this.subScreen == 1) {
      this.options.page = this.$route?.query?.p1 ?? 1;
      this.options.itemsPerPage =
        this.$route?.query?.pp1 ?? this.perPageOptions[0] ?? 20;
    } else {
      this.options.page = 1;
      this.options.itemsPerPage = this.perPageOptions[0] ?? 20;
    }
  },

  methods: {
    onFiltersChange(v) {
      delete this.options.product;
      delete this.options.fleetId;
      delete this.options.is_independent_fleet;
      delete this.options.oem_b2b_ids;
      delete this.options.tenant_id;
      if (this.firstDrop) {
        //for pageination this func invoke as mounted
        this.options.page = 1;
        // this.options.itemsPerPage = 20;
      }
      this.options = { ...this.options, ...v };
      this.firstDrop = true;
    },
    onPaginationChange(val) {
      this.options = { ...this.options, ...val };
    },
    // whenRowSelect() {
    //   setTimeout(() => {
    //     this.$emit("input", this.selectedRows);
    //   }, 100);
    // },
    //
    itemRowBackground(item) {
      let rowBg = "";
      if (this.telematicRows && this.authUser.isAdmin) {
        if (!item.is_synced) {
          rowBg = "invalid-sync-telematics";
        }

        if (item.vehicle_meta !== null) {
          let row = item.vehicle_meta.checks_passed;
          if (row !== undefined) {
            if (
              row.is_bms_check_passed === false ||
              row.is_ecu_check_passed === false ||
              row.is_iot_check_passed === false
            ) {
              rowBg = "invalid-telematics";
            } else if (
              row.is_bms_check_passed === null &&
              row.is_ecu_check_passed === null &&
              row.is_iot_check_passed === null
            ) {
              rowBg = "null-telematics";
            }
          }
        }
        if (item.is_open) {
          rowBg = "modal-active";
        }
      }
      if (this.telematicMassageRows) {
        rowBg = "valid-telematics";

        if (!item.bms_pass || !item.ecu_pass || !item.iot_pass) {
          rowBg = "invalid-telematics";
        }
      }
      return rowBg;
    },
    downloadFile(val) {
      this.$emit("isExport", val);
    },
  },
};
</script>
<style lang="sass" scoped>

.listing-top-header
  display: flex
  justify-content: center
  // align-items: baseline
.gap
  gap: 1.5rem

.filters
  display: flex
  // gap: 1rem
  .filterDropDown
    flex: 1
    margin-bottom: 1rem
  :deep .button-modals
    .v-btn
      margin-left: 10px
    // display: flex
    // gap: 1rem
    // margin-bottom: 1rem



@media only screen and (max-width: 640px)
  .filters
    // flex-direction: column
    justify-content: space-between
    .filterDropDown
      flex: 0

    .button-modals
      display: flex
      justify-content: flex-end
</style>
